import React, { Component } from "react";
import List from "../../components/List/List";

export default class ContentFeed extends Component {
  constructor(props) {
    super(props);
    this.addPath = "/admin/content/feeds/add";
    this.editPath = "/admin/content/feeds/edit";
  }

  render() {
    return (
      <List
        name="Content Feed"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/content/feeds"
        listApiUrl="/admin/content/feeds"
        model={[
          { col: "Day", row: "id" },
          {
            col: "Avalon id",
            row: "avalon_id",
            normalizer: (data) => {
              return (
                <ul>
                  {data.articles.map((obj, index) => {
                    return <li key={index}>{obj.avalon_id}</li>;
                  })}
                </ul>
              );
            },
          },
          {
            col: "Articles",
            row: "articles",
            normalizer: (data) => {
              return (
                <ul>
                  {data.articles.map((obj, index) => {
                    return (
                      <li key={index}>
                        <a
                          href={obj.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {obj.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
      ></List>
    );
  }
}
