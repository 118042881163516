import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import { BodyRecompositionTranslationModel } from "../../../models/BodyRecompositionTranslation";

export default class BodyRecompositionTranslationAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Body Recomposition Translation";
    this.url = "/admin/translations/body-recompositions";
    this.skipPrepopulation = true;
  }

  locationState = () => {
    return this.props.location.state || { language: {}, translation: {} };
  };

  getTitle = () => {
    return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${
      this.locationState().language.title
    }`;
  };

  getEntity = () => {
    let state = this.locationState();
    let translation = state.translation || {};

    return BodyRecompositionTranslationModel(
      state.resource || null,
      state.language.key || null,
      translation.name || null
    );
  };
}
