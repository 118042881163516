export const BodyRecompositionTranslationModel = (
  body_recomposition_id,
  language_id,
  name
) => {
  return {
    body_recomposition_id: {
      value: body_recomposition_id,
      hidden: true,
    },
    language_id: {
      value: language_id,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: name,
    },
  };
};

export const BodyRecompositionTranslationFilter = () => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
  };
};
