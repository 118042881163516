import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { ApiConstantsContext } from "../../../variables/ApiConstantsContext";
import { Col, Row, Table } from "react-bootstrap";
import Card from "../../../components/Card/Card";
import { cartesian, toTitleCase } from "../../../utils/Utils";
import { API } from "aws-amplify";
import Loader from "../../../components/Loader/Loader";

export default class AdditionalWorkout extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/workouts/additional/add";
    this.editPath = "/admin/workouts/additional/edit";

    this.state = {
      workouts: [],
      isLoading: true,
    };

    this.clusterKeys = ["gender", "fitness_level", "age_group", "bmi"];
  }

  generateAddEditLink = (element, clusterParams) => {
    let style = {
      color: "red",
      fontSize: "1.5em",
    };
    if (element !== undefined) {
      return (
        <NavLink
          to={{
            pathname: this.editPath + "/" + element.cluster_id,
            state: {
              resource: element,
            },
          }}
          style={{
            ...style,
            color: element.count === 0 ? "red" : "green",
          }}
        >
          {element.count}
        </NavLink>
      );
    }
    return (
      <NavLink
        to={{
          pathname: this.addPath,
          state: {
            clusterParams: clusterParams,
          },
        }}
        style={style}
      >
        0
      </NavLink>
    );
  };

  formatClusterKey = (...elems) => {
    return elems.join(".");
  };

  getClusterKeyFromObject = (clusterKeys, obj) => {
    return this.formatClusterKey(
      ...clusterKeys.map((key) => {
        return obj[key];
      })
    );
  };

  componentDidMount() {
    API.get("admin", "/admin/workouts/additional")
      .then((data) => {
        let workouts = data.reduce((obj, item) => {
          obj[this.getClusterKeyFromObject(this.clusterKeys, item)] = item;
          return obj;
        }, {});
        this.setState({
          isLoading: false,
          workouts: workouts,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getKeyValues(key) {
    let keys = Object.keys(this.context.get_const_items(key));
    // Hack to skip 'other' gender
    if (key === "gender") {
      keys = keys.filter((value) => value !== "other");
    }
    return keys;
  }

  buildHeader = (clusterKeys) => {
    let currentSize = 1;
    let collSpans = [];
    for (const key of clusterKeys.slice().reverse()) {
      let itemsSpanNum = this.getKeyValues(key).length;
      collSpans.unshift(currentSize);
      currentSize *= itemsSpanNum;
    }
    let currentRepeat = 1;

    let repeats = [];
    for (const key of clusterKeys) {
      let itemsNum = this.getKeyValues(key).length;
      repeats.push(currentRepeat);
      currentRepeat *= itemsNum;
    }

    return (
      <thead>
        {clusterKeys.map((key, index) => {
          let collSpan = collSpans[index];
          let repeatNum = repeats[index];
          return (
            <tr key={index}>
              <th />
              {Array(repeatNum)
                .fill()
                .map((_, i) => {
                  return this.getKeyValues(key).map((value, j) => {
                    return (
                      <th
                        className={"text-center"}
                        key={(index + 1) * (j + 1) * (i + 1)}
                        colSpan={collSpan}
                      >
                        <strong>{this.context.get_const(key, value)}</strong>
                      </th>
                    );
                  });
                })}
            </tr>
          );
        })}
      </thead>
    );
  };

  render() {
    let verticalKey = this.clusterKeys[this.clusterKeys.length - 1];
    let verticalValues = this.getKeyValues(
      this.clusterKeys[this.clusterKeys.length - 1]
    );
    let horizontalValues = cartesian(
      ...this.clusterKeys.slice(0, -1).map((key) => {
        return this.getKeyValues(key);
      })
    );

    return (
      <div className="content">
        <Row>
          <Col md={12}>
            <Card
              content={
                <Loader isLoading={this.state.isLoading}>
                  <Table striped hover bordered>
                    {this.buildHeader(this.clusterKeys.slice(0, -1))}
                    <tbody>
                      {verticalValues.map((vval, indexa) => {
                        return (
                          <tr key={indexa}>
                            <th className={"text-center"} key={indexa}>
                              <strong>{`${toTitleCase(
                                verticalKey
                              )} ${this.context.get_const(
                                verticalKey,
                                vval
                              )}`}</strong>
                            </th>
                            {horizontalValues.map((hval, indexb) => {
                              let currKey = this.formatClusterKey(
                                ...hval,
                                vval
                              );
                              let clusterParams = this.clusterKeys.reduce(
                                (obj, item, index) => {
                                  obj[item] = [...hval, vval][index];
                                  return obj;
                                },
                                {}
                              );
                              let element = this.state.workouts[currKey];
                              return (
                                <td className={"text-center"} key={indexb}>
                                  {this.generateAddEditLink(
                                    element,
                                    clusterParams
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Loader>
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}
