export default {
  app: {
    name: "Calorie Tracker",
  },
  userGroups: {
    admin: "admin",
    viewer: "viewer",
    editor: "editor",
    support: "support",
  },
};
