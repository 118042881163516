import React, { Component } from "react";
import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { TrainingPlanFilter } from "../../models/TrainingPlan";

export default class TrainingPlan extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/training-plans/add";
    this.editPath = "/admin/training-plans/edit";
  }

  enumListNormalizer(data, key) {
    return (
      <ul>
        {data[key].map((item, index) => {
          return <li key={index}>{this.context.get_const(key, item)}</li>;
        })}
      </ul>
    );
  }

  render() {
    let get_const = this.context.get_const;
    return (
      <List
        name="Training Plans"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/training-plans"
        listApiUrl="/admin/training-plans"
        model={[
          { col: "Id", row: "id" },
          {
            col: "Cover",
            row: "cover",
            normalizer: (data) => {
              return (
                <a href={data.cover} target={"blank"}>
                  <img alt={data.name} src={data.cover} width="150" />
                </a>
              );
            },
          },
          { col: "Name", row: "name" },
          {
            col: "Activity Type",
            row: "activity_type",
            normalizer: (data) =>
              get_const("training_plan_activity_type", data.activity_type),
          },
          { col: "Phases", row: "phases_count" },
          {
            col: "Avg WO duration",
            row: "average_workout_duration",
            normalizer: (data) =>
              new Date(1000 * data.average_workout_duration)
                .toISOString()
                .substr(11, 8),
          },
          {
            col: "Fitness Level",
            row: "fitness_level",
            normalizer: (data) =>
              this.enumListNormalizer(data, "fitness_level"),
          },
          {
            col: "Gender",
            row: "gender",
            normalizer: (data) => this.enumListNormalizer(data, "gender"),
          },
          {
            col: "BMI",
            row: "bmi",
            normalizer: (data) => this.enumListNormalizer(data, "bmi"),
          },
          {
            col: "Age Group",
            row: "age_group",
            normalizer: (data) => this.enumListNormalizer(data, "age_group"),
          },
          {
            col: "Status",
            row: "status",
            normalizer: (data) => {
              let colors = {
                published: "green",
                unpublished: "orange",
              };
              return (
                <p style={{ color: colors[data.status] || "black" }}>
                  {get_const("training_plan_status", data.status)}
                </p>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
        filterObject={TrainingPlanFilter(this.context.constants)}
      />
    );
  }
}
