import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import { ReviewerAccountModel } from "../../models/ReviewerAccount";

export default class ReviewerAccountAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.url = "/admin/reviewer-accounts";
    this.name = "Reviewer Account";
    this.redirectUrl = "/admin/reviewer-accounts";
  }

  getEntity = () => {
    return ReviewerAccountModel();
  };

  render() {
    return React.cloneElement(super.render(), { md: 12 });
  }
}
