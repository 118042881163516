import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import defaults from "../../defaults";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  menuClassName(layout, path, index, total) {
    let routeName = layout + path;
    let active =
      this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    let first = index === 0 ? "first" : "";
    let last = index === total - 1 ? "last" : "";
    return [active, first, last].join(" ");
  }

  generateMenus(routes) {
    return routes.map((prop, key) => {
      if (!prop.redirect && !prop.invisible)
        return (
          <li
            className={
              prop.upgrade
                ? `active active-pro`
                : this.menuClassName(prop.layout, prop.path, key, routes.length)
            }
            key={key}
          >
            <NavLink
              to={prop.layout + prop.path}
              className={`nav-link ${prop.submenu ? "submenu" : ""}`}
              activeClassName="active"
            >
              {prop.icon ? <i className={prop.icon} /> : null}
              <p>{prop.name}</p>
            </NavLink>
          </li>
        );
      return null;
    });
  }

  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")",
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
        {this.props.hasImage ? (
          <div className="sidebar-background" style={sidebarBackground} />
        ) : null}
        <div className="logo text-center">
          <a href="/" className="simple-text logo-normal">
            {defaults.app.name}
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
            {this.generateMenus(this.props.routes)}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
