import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import { TagModel } from "../../models/Tag";

export default class TagAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Tags";
    this.url = "/admin/blocks/tags";

    this.state = {
      exercises: [],
      tags: [],
    };
  }

  getEntity = () => {
    return TagModel();
  };

  render() {
    return React.cloneElement(super.render(), { md: 12 });
  }
}
