import React, { Component } from "react";
import List from "../../components/List/List";
import Button from "../../components/CustomButton/CustomButton";
import API from "@aws-amplify/api";

class Content extends Component {
  constructor(props) {
    super(props);
    this.addPath = "/admin/content/add";
    this.editPath = "/admin/content/edit";
    this.syncUrl = "/admin/content/sync";
  }

  syncContent = (event) => {
    let button = event.target;
    button.disabled = true;

    API.get("admin", this.syncUrl)
      .then((data) => {
        this.props.handleClick("Successfully synced", "success", "tr");
      })
      .catch((error) => {
        this.props.handleClick(
          "Failed to sync: " + JSON.stringify(error.response.data),
          "error",
          "tr"
        );
      })
      .finally(() => {
        button.disabled = false;
      });
  };

  render() {
    return (
      <List
        disableActions
        headerButtons={[
          () => {
            return (
              <Button
                fill
                variant="primary"
                bsStyle="success"
                onClick={(e) => this.syncContent(e)}
              >
                Synchronize articles
              </Button>
            );
          },
        ]}
        name="Content"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/content"
        listApiUrl="/admin/content"
        model={[
          { col: "Avalon Id", row: "external_id" },
          { col: "Title", row: "title" },
          { col: "Category code", row: "category_code" },
          { col: "Category name", row: "category_name" },
          {
            col: "Url",
            row: "url",
            normalizer: (data) => {
              return (
                <a href={data.url} target="_blank" rel="noopener noreferrer">
                  Click
                </a>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
      ></List>
    );
  }
}

export default Content;
