export const BodyRecompositionModel = (constants) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
  };
};

export const BodyRecompositionFilter = (constants) => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    search: {
      type: "input",
      value: "",
    },
  };
};
