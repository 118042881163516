import React from "react";
import { AudioTrackCategoryModel } from "../../../../models/AudioTrackCategory";
import BaseAddEditComponent from "../../../Generic/BaseAddEditComponent";

export default class AudioTrackCategoryAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Audio Track Category";
    this.url = "/admin/audio-tracks/categories";
  }

  getEntity = () => {
    return AudioTrackCategoryModel(this.context.constants);
  };

  render() {
    return React.cloneElement(super.render(), {
      md: 12,
    });
  }
}
