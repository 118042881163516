import TagAdd from "./TagAdd";
import React from "react";

export default class TagEdit extends TagAdd {
  constructor(props) {
    super(props);
    this.skipPrepopulation = true;
  }

  render() {
    return React.cloneElement(super.render(), {
      md: 12,
      responseData: this.locationState().tag,
    });
  }
}
