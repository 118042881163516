import React, {useState} from "react";
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import {Link} from "react-router-dom";
import {parse} from 'csv-parse/lib/sync';
import {stringify} from 'csv-stringify/lib/sync';


function stripTitle(csvString) {
  // Amplitude prefixes each CSV file with the chart title which breaks csv parsing
  return csvString.replace(/"[^"]+"\r\n\r\n\r\n/g, "");
}

function normalizeReason(reasonStr) {
  reasonStr = reasonStr.trim();
  const reasons = {
    "browsing": "app browsing",
    "localization": "lack of localization",
    "no_language": "lack of localization",
    "obvious": "obvious",
    "well known": "obvious",
    "not_relevant": "not relevant",
    "not_new": "obvious",
    "tired": "tired of reading",
    "busy": "busy, have other business",
    "loading": "loading speed",
  }

  return reasons[reasonStr] || reasonStr;
}

function parseCourseVersion(courseVersionStr) {
  courseVersionStr = courseVersionStr.trim()

  if (courseVersionStr === "") {
    return courseVersionStr;
  }

  if (courseVersionStr === "old_user_service_name") {
    return "1.0, 2.0"
  }

  if (courseVersionStr === "Begin") {
    return "6.0";
  }

  const match = courseVersionStr.match(/^Course v\.(.+)$/);

  if (match !== null) {
    return match[1];
  }

  return "";
}

function parseTitle(name) {
  const parts = name.trim().split(/\s+/)
  const [lessonNumberStr, ...rest] = parts;
  const lessonNumber = lessonNumberStr.endsWith(".") ? lessonNumberStr.slice(0, -1) : lessonNumberStr;
  if (rest[0].startsWith("v.")) {
    return [
      [lessonNumber, rest[0]].join(" "),
      rest.slice(1).join(" ")
    ]
  }

  return [lessonNumber, rest.join(" ")];
}


function ChartGroup({group}) {
  const [fileContents, setFileContents] = useState("");
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [periodValue, setPeriodValue] = useState("");

  function handleExportFileChange(event) {
    const files = event.currentTarget?.files;

    if (!files.length) {
      setFileContents("");
      setSelectedFileName(null);
    }
    setSelectedFileName(files[0].name)
    const reader = new FileReader();
    reader.readAsText(files[0], "utf-8");

    reader.onload = (evt) => {
      setFileContents(evt.target.result);
    }
  }

  function handleSubmit(event, chart) {
    event.preventDefault();
    const records = parse(stripTitle(fileContents));
    const formattedRecords = chart.formatter(records.slice(1), periodValue);
    download("formatted-" + selectedFileName, stringify(formattedRecords));
  }

  function handlePeriodInputChange(e) {
    setPeriodValue(e.target.value);
  }

  function download(filename, data) {
    const blob = new Blob([data], {type: 'text/csv'});
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  return <Tabs defaultActiveKey={group.charts[0].title} id="chart-tabs">
    {
      group.charts.map(chart =>
          <Tab title={chart.title} eventKey={chart.title} key={chart.title}>
            <form onSubmit={(event) => handleSubmit(event, chart)}>
              <HelpBlock>
                Export <a href={chart.link} target="_blank">Amplitude chart <i className="pe-7s-next-2"></i></a> as CSV file and select it in the field bellow.
                Clicking "Format & Download" will start a download of a new, formatted CSV file.
              </HelpBlock>

              <FormGroup controlId="exportFileInput">
                <ControlLabel>Exported CSV File</ControlLabel>
                <FormControl
                    required={true}
                    type="file"
                    onChange={handleExportFileChange}
                />
              </FormGroup>
              <FormGroup controlId="periodInput">
                <ControlLabel>Period</ControlLabel>
                <FormControl
                    type="text"
                    value={periodValue}
                    placeholder="Period"
                    required={true}
                    onChange={handlePeriodInputChange}
                />
                <HelpBlock>This will be the value of "period" column in the formatted CSV file.</HelpBlock>
              </FormGroup>
              <FormControl.Feedback/>
              <Button type="submit" bsStyle="primary">
                Format & Download
              </Button>
            </form>
          </Tab>
      )
    }
  </Tabs>
}

function CourseAnalyticsFormatting() {
  const chartGroups = [
    {
      title: "iOS",
      charts: [
        {
          title: "Incomplete_reasons",
          link: "https://app.amplitude.com/analytics/amazingapps/chart/w1e3m35",
          formatter: (records, period) => {
            const header = [
              "Story #",
              "Story Title",
              "Problem tag",
              "number of requests",
              "period",
              "platform",
              "course version",
            ];

            const formattedRows = records.map(record => {
              const [name, reason, courseVersion,, clickCount] = record;
              const [lessonNumber, title] = parseTitle(name);

              return [
                lessonNumber,
                title,
                normalizeReason(reason),
                clickCount.trim(),
                period,
                "ios",
                parseCourseVersion(courseVersion),
              ];
            });

            return [
              header,
              ...formattedRows,
            ];
          }
        },
        {
          title: "Incomplete_reasons text",
          link: "https://app.amplitude.com/analytics/amazingapps/chart/sy9kckh",
          formatter: (records, period) => {
            const header = [
              "Story #",
              "Story Title",
              "Reason_text",
              "Translation",
              "user id",
              "Problem tag",
              "Solution",
              "number of requests(unique)",
              "course version",
              "period",
              "platform",
            ];

            const formattedRows = records.map(record => {
              const [name, reasonText,, courseVersion, userId, clickCount] = record;
              const [lessonNumber, title] = parseTitle(name);
              return [
                  lessonNumber,
                  title,
                  reasonText.trim(),
                  "",
                  userId.trim(),
                  "",
                  "",
                  clickCount,
                  parseCourseVersion(courseVersion),
                  period,
                  "ios"
              ]
            });

            return [
                header,
                ...formattedRows,
            ]
          }
        },
        {
          title: "Text feedback",
          link: "https://app.amplitude.com/analytics/amazingapps/chart/s1a15mr",
          formatter: (records, period) => {
            const header = [
              "Story #",
              "Story Title",
              "Feedback",
              "Translation (if needed)",
              "user id",
              "app version",
              "Tag 1",
              "Tag 2",
              "additional note",
              "Solution status",
              "number of requests",
              "course version",
              "period",
              "platform",
            ];

            const formattedRows = records.map(record => {
              const [name, feedbackText, , courseVersion, userId, clickCount] = record;
              const [lessonNumber, title] = parseTitle(name);
              return [
                lessonNumber,
                title,
                feedbackText.trim(),
                "",
                userId.trim(),
                "",
                "",
                "",
                "",
                "",
                clickCount,
                parseCourseVersion(courseVersion),
                period,
                "ios",
              ]
            }).filter(record => record !== null);

            return [
              header,
              ...formattedRows
            ]
          }
        }
      ]
    },
    {
      title: "Android",
      charts: [
        {
          title: "Incomplete_reasons",
          link: "https://app.amplitude.com/analytics/amazingapps/chart/8vvwolj",
          formatter: (records, period) => {
            const header = [
              "Story #",
              "Story Title",
              "Problem tag",
              "number of requests",
              "period",
              "platform",
              "course version",
            ];

            const formattedRows = records.map(record => {
              const [name, reason, courseVersion, clickCount] = record;
              const [lessonNumber, title] = parseTitle(name);

              return [
                lessonNumber,
                title,
                normalizeReason(reason),
                clickCount.trim(),
                period,
                "android",
                parseCourseVersion(courseVersion),
              ];
            });

            return [
              header,
              ...formattedRows,
            ];
          }
        },
        {
          title: "Incomplete_reasons text",
          link: "https://app.amplitude.com/analytics/amazingapps/chart/fmffhim",
          formatter: (records, period) => {
            const header = [
              "Story #",
              "Story Title",
              "Reason_text",
              "Translation",
              "user id",
              "Problem tag",
              "Solution",
              "number of requests(unique)",
              "course version",
              "period",
              "platform",
            ];

            const formattedRows = records.map(record => {
              const [name, reasonText, courseVersion, userId, clickCount] = record;
              const [lessonNumber, title] = parseTitle(name);
              return [
                  lessonNumber,
                  title,
                  reasonText.trim(),
                  "",
                  userId.trim(),
                  "",
                  "",
                  clickCount,
                  parseCourseVersion(courseVersion),
                  period,
                  "android",
              ]
            });

            return [
                header,
                ...formattedRows,
            ]
          }
        },
        {
          title: "Text feedback",
          link: "https://app.amplitude.com/analytics/amazingapps/chart/hyxg1t3",
          formatter: (records, period) => {
            const header = [
              "Story #",
              "Story Title",
              "Feedback",
              "Translation (if needed)",
              "user id",
              "app version",
              "Tag 1",
              "Tag 2",
              "additional note",
              "Solution status",
              "number of requests",
              "course version",
              "period",
              "platform",
            ];

            const formattedRows = records.map(record => {
              const [name, feedbackText, , courseVersion, userId, clickCount] = record;
              const [lessonNumber, title] = parseTitle(name);
              return [
                lessonNumber,
                title,
                feedbackText.trim(),
                "",
                userId.trim(),
                "",
                "",
                "",
                "",
                "",
                clickCount,
                parseCourseVersion(courseVersion),
                period,
                "android",
              ]
            }).filter(record => record !== null);

            return [
              header,
              ...formattedRows
            ]
          }
        },
      ]
    },
  ]

  return <Grid fluid className="course-analytics">
    <Row md={12}>
      <Col md={6}>
        <Tabs
            defaultActiveKey={chartGroups[0].title}
            id="chart-group-tabs"
            className="mb-3"
        >
          {
            chartGroups.map(group =>
                <Tab title={group.title} eventKey={group.title} key={group.title}>
                  <ChartGroup group={group}/>
                </Tab>
            )
          }
        </Tabs>

        <div className="footer">
          <Link to={"/admin/tools"}>Back</Link>
        </div>
      </Col>
    </Row>
  </Grid>
}

export default CourseAnalyticsFormatting;