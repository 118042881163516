export const BodyPartTranslationModel = (body_part_id, language_id, name) => {
  return {
    body_part_id: {
      value: body_part_id,
      hidden: true,
    },
    language_id: {
      value: language_id,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: name,
    },
  };
};

export const BodyPartTranslationFilter = () => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
  };
};
