import { TrainingPlanPhaseModel } from "../../../models/TrainingPlanPhase";
import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import React from "react";

export default class TrainingPlanPhaseAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Training Plan Phase";
    this.url = "/admin/training-plans/phases";
  }

  getEntity = () => {
    return TrainingPlanPhaseModel(this.context.constants);
  };

  render() {
    return React.cloneElement(super.render(), { md: 12 });
  }
}
