import AdditionalWorkoutAdd from "./AdditionalWorkoutAdd";
import { AdditionalWorkoutListUpdateModel } from "../../../models/AdditionalWorkout";
import React from "react";

export default class AdditionalWorkoutEdit extends AdditionalWorkoutAdd {
  getTitle = () => {
    let resource = this.locationState().resource;
    let get_const = this.context.get_const;
    return (
      <div>
        <div>{`Edit ${this.name} [${this.props.match.params.id}]`}</div>
        <div>
          <strong>Age group: </strong>
          {`${get_const("age_group", resource.age_group)}`}
        </div>
        <div>
          <strong>Gender: </strong>
          {`${get_const("gender", resource.gender)}`}
        </div>
        <div>
          <strong>Fitness Level: </strong>
          {`${get_const("fitness_level", resource.fitness_level)}`}
        </div>
        <div>
          <strong>BMI: </strong>
          {`${get_const("bmi", resource.bmi)}`}
        </div>
      </div>
    );
  };

  getEntity = () => {
    return AdditionalWorkoutListUpdateModel(
      this.context.constants,
      this.debouncedLoadOptions
    );
  };
}
