export const FitnessComponentModel = (constants) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    description: {
      type: "input",
      validationRules: ["required", { max: "600" }],
      value: "",
      selectProps: {
        rows: 7,
        componentClass: "textarea",
        as: "textarea",
      },
    },
  };
};

export const FitnessComponentFilter = (constants) => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    search: {
      type: "input",
      value: "",
    },
  };
};
