import React from "react";
import { components } from "react-select";
import {
  buildSelector,
  constToSelectOptions,
  formatDuration,
  generateBadgeRow,
} from "../utils/Utils";
import { NavLink } from "react-router-dom";
import { API } from "aws-amplify";

const Option = ({ children, ...props }) => {
  let option = props.data;
  let exercises = (option.exercises || []).map((val) => val.name);

  return (
    <components.Option {...props}>
      <div className="clearfix">
        <div className="mb-9 pull-left" style={{ marginLeft: "10px" }}>
          <p style={{ margin: "0" }}>
            <NavLink
              to={{
                pathname: "/admin/blocks/edit/" + option.id,
              }}
              target="_blank"
              style={{ color: "black" }}
            >
              <strong>
                [{option.id}] {option.name}
              </strong>{" "}
              <span className="glyphicon glyphicon-new-window" />
            </NavLink>
          </p>
          {generateBadgeRow("Body parts", option.body_parts)}
          <br />
          {generateBadgeRow("Tags", option.tags, "danger")}
          <br />
          {generateBadgeRow("Exercises", exercises, "success")}
          <br />

          <span style={{ margin: "0" }}>
            Total Time: <strong>{formatDuration(option.total_time)}</strong>
          </span>
          <br />
        </div>
      </div>
    </components.Option>
  );
};

export const WorkoutBlock = (constants, loadOptions) => {
  let objCache = {};

  return {
    block_id: {
      type: "select",
      validationRules: "required",
      onChangeEvent: "handleSelect",
      value: "",
      md: 8,
      requestNormalizer: function (data) {
        return parseInt(data.id);
      },
      responseNormalizer: (option) => {
        let block_id = option;
        if (objCache[block_id] !== undefined) {
          return objCache[block_id];
        }
        return API.get("admin", `/admin/blocks/${block_id}`)
          .then((data) => {
            objCache[block_id] = data;
            return data;
          })
          .catch((error) => {
            console.error(error);
            return {};
          });
      },
      selectProps: {
        components: { Option },
        getOptionLabel: (option) => {
          return `[${option["id"]}] ${option["name"]}`;
        },
        getOptionValue: (option) => {
          return option["id"];
        },
        pageSize: 10,
        minMenuHeight: 300,
        maxMenuHeight: 1000,
        loadOptions: loadOptions,
        // defaultOptions: true,
      },
    },
    loop_count: {
      inputType: "number",
      validationRules: "required|min:0,num",
      value: "",
      requestNormalizer: function (data) {
        return parseInt(data);
      },
      md: 2,
    },
    block_type: {
      ...buildSelector(constToSelectOptions(constants, "block_type")),
      md: 2,
    },
  };
};

export const WorkoutBlockListModel = (constants, loadOptions) => {
  return {
    blocks: {
      type: "table_collection",
      value: [],
      prototype: WorkoutBlock(constants, loadOptions),
      onChangeEvent: "handleCollection",
      validationRules: "required",
      md: 12,
      requestNormalizer: function (data) {
        return data;
      },
    },
  };
};
