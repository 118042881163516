import {Col, Grid, Row} from "react-bootstrap";
import React from "react";
import Card from "../../components/Card/Card";
import {Link} from "react-router-dom";

function Tools() {
  return <div className="content">
    <Grid fluid>
      <Row md={12}>
        <Col md={12}>
          <p>
            Miscellaneous tools to automate boring tasks can be found here. See the links for the list of available
            tools.
          </p>

          <ul>
            <li>
              <Link to={"/admin/tools/course-analytics-formatting"}>
                Format Amplitude Dashboard Export File
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Grid>
  </div>
}

export default Tools;