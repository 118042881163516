import React, { Component } from "react";
import { API } from "aws-amplify";

const ApiConstantsContext = React.createContext();

class ApiConstantsContextProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      constants: {},
      get_const: (key, item) => {
        if (!this.state.constants) {
          return null;
        }
        if (item) {
          return this.state.constants[key]["items"][item]["title"];
        }
        return this.state.constants[key]["title"];
      },
      get_const_items: (key) => {
        if (Object.keys(this.state.constants).length === 0) {
          return {};
        }
        return this.state.constants[key]["items"] || {};
      },
    };
  }

  componentDidMount() {
    API.get("admin", "/admin/constants")
      .then((data) => {
        this.setState({
          constants: data,
        });
      })
      .catch((error) => {
        console.log("Error " + error);
      });
  }

  render() {
    return (
      <ApiConstantsContext.Provider value={this.state}>
        {this.props.children}
      </ApiConstantsContext.Provider>
    );
  }
}

export { ApiConstantsContextProvider, ApiConstantsContext };
