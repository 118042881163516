export const ExerciseTranslationModel = (
  exercise_id,
  language_id,
  name,
  audio_url,
  instruction
) => {
  return {
    exercise_id: {
      value: exercise_id,
      hidden: true,
    },
    language_id: {
      value: language_id,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: name,
    },
    audio_url: {
      type: "input",
      validationRules: "",
      value: audio_url,
    },
    instruction: {
      type: "input",
      validationRules: "",
      value: instruction,
    },
  };
};

export const ExerciseTranslationFilter = () => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
  };
};
