import React, { Component } from "react";
import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { ExerciseFilter } from "../../models/Exercise";
import { constList } from "../../utils/Utils";

export default class Exercise extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/exercises/add";
    this.editPath = "/admin/exercises/edit";
  }

  render() {
    let constants = this.context.constants;
    let c = this.context.get_const;
    return (
      <List
        name="Exercises"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/exercises"
        listApiUrl="/admin/exercises"
        model={[
          { col: "Id", row: "id" },
          {
            col: "Preview",
            row: "preview",
            normalizer: (data) => {
              return <img alt={data.name} src={data.preview} width="150" />;
            },
          },
          { col: "Name", row: "name" },
          {
            col: "Body Parts",
            row: "body_parts",
            normalizer: (data) => {
              return constList(c, data, "body_parts", "body_part");
            },
          },
          {
            col: "Equipment",
            row: "equipment",
            normalizer: (data) => {
              return constList(c, data, "equipment");
            },
          },
          {
            col: "Exercise Level",
            row: "exercise_level",
            normalizer: (data) => {
              return c("exercise_level", data.exercise_level);
            },
          },
          { col: "Base Pace", row: "base_pace" },
          { col: "Base Mets", row: "base_mets" },
          { col: "1RM", row: "one_rm" },
          {
            col: "Tags",
            row: "tags",
            normalizer: (data) => {
              return constList(c, data, "tags");
            },
          },
          { col: "Workouts", row: "workouts" },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={ExerciseFilter(constants)}
        userHasPermission={this.props.userHasPermission}
      />
    );
  }
}
