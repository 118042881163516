import React, { Component } from "react";
import { ApiConstantsContext } from "../../../variables/ApiConstantsContext";
import List from "../../../components/List/List";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import defaults from "../../../defaults";

let g = defaults.userGroups;

export default class AudioTrack extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/audio-tracks/add";
    this.editPath = "/admin/audio-tracks/edit";
  }

  render() {
    const params = Object.fromEntries(
      new URLSearchParams(this.props.location.search).entries()
    );

    let filterParams = {};
    if (params.category_id !== undefined) {
      filterParams["category_id"] = params.category_id;
    }

    return (
      <List
        name="Audio Track Categories"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/audio-tracks"
        listApiUrl="/admin/audio-tracks"
        filterParams={filterParams}
        headerButtons={[
          (list) => {
            return (
              <NavLink
                allowedGroups={[g.admin, g.editor]}
                to={{
                  pathname: list.props.addPath,
                  state: {
                    category_id: params.category_id,
                  },
                }}
                className="btn btn-success"
              >
                Add
              </NavLink>
            );
          },
        ]}
        actions={[
          (list, data) => {
            return (
              <NavLink
                to={{
                  pathname: list.props.editPath + "/" + data.id,
                  state: {
                    data: data,
                  },
                }}
                className="btn btn-primary"
              >
                Edit
              </NavLink>
            );
          },
          (list, data) => {
            return (
              <Button
                allowedGroups={[g.admin, g.editor]}
                className="btn btn-danger"
                onClick={(e) => list.handleRemove(data.id, e)}
              >
                Delete
              </Button>
            );
          },
        ]}
        model={[
          { col: "Id", row: "id" },
          {
            col: "Name",
            row: "name",
            normalizer: (data) => {
              return (
                <a href={`/admin/translations/audio-tracks/${data.id}`}>
                  {data.name}
                </a>
              );
            },
          },
          { col: "Time", row: "time" },
          { col: "Repetitions", row: "repetitions" },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
      />
    );
  }
}
