import React, { Component } from "react";
import { ApiConstantsContext } from "../../../variables/ApiConstantsContext";
import List from "../../../components/List/List";
import { BodyRecompositionFilter } from "../../../models/BodyRecomposition";

export default class BodyRecomposition extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/training-plans/body-recompositions/add";
    this.editPath = "/admin/training-plans/body-recompositions/edit";
  }

  render() {
    return (
      <List
        name="Body Recompositions"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/training-plans/body-recompositions"
        listApiUrl="/admin/training-plans/body-recompositions"
        model={[
          { col: "Id", row: "id" },
          { col: "Name", row: "name" },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
        filterObject={BodyRecompositionFilter(this.context.constants)}
      />
    );
  }
}
