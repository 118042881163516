import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import { WorkoutTypeTranslationFilter } from "../../../models/WorkoutTypeTranslation";

export default class WorkoutTypeTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Workout Type Translations";
    this.addPath = "/admin/translations/workout-types/add";
    this.editPath = "/admin/translations/workout-types/edit";
    this.removeUrl = "/admin/translations/workout-types";
    this.listApiUrl = "/admin/translations/workout-types";
    this.constantKey = "workout_type";
  }

  generateFilter = () => {
    return WorkoutTypeTranslationFilter();
  };
}
