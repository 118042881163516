import {
  buildSelector,
  constToSelectOptions,
  TranslationMultiSelect,
  yesNoSelector,
} from "../utils/Utils";
import { API } from "aws-amplify";
import debounce from "debounce-promise";
import { components } from "react-select";
import React from "react";
import { NavLink } from "react-router-dom";

const loadPhases = (input) => {
  let queryParams = {
    per_page: "25",
  };

  if (input.length > 0) {
    let [searchParam, searchValue] = detectSearchParameter(input);
    queryParams[searchParam] = searchValue;
  }

  return API.get("admin", "/admin/training-plans/phases", {
    queryStringParameters: queryParams,
  })
    .then((data) => {
      let items = data.items.reduce(function (obj, item) {
        obj[item.id] = item;
        return obj;
      }, {});
      return Object.values(items);
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

const detectSearchParameter = (input) => {
  input = input.toLowerCase();
  if (!isNaN(input)) {
    return ["ids", JSON.stringify([parseFloat(input)])];
  }
  return ["search", input];
};

const Option = ({ children, ...props }) => {
  let option = props.data;

  return (
    <components.Option {...props}>
      <div className="clearfix">
        <div className="mb-9 pull-left" style={{ marginLeft: "10px" }}>
          <p style={{ margin: "0" }}>
            <NavLink
              to={{
                pathname: "/admin/training-plans/phases/edit/" + option.id,
              }}
              target="_blank"
              style={{ color: "black" }}
            >
              <strong>
                [{option.id}] {option.name}
              </strong>{" "}
              <span className="glyphicon glyphicon-new-window" />
            </NavLink>
          </p>
          <span style={{ margin: "0" }}>
            Service name: <strong>{option.service_name || "-"}</strong>
          </span>
          <br />
          <span style={{ margin: "0" }}>
            Required Completes:{" "}
            <strong>{option.required_completes_count || "-"}</strong>
          </span>
          <br />
          <br />
          <span style={{ margin: "0" }}>
            Workouts Count: <strong>{option.workouts_count}</strong>
          </span>
          <br />
          <span style={{ margin: "0" }}>
            Description: <strong>{option.description}</strong>
          </span>
          <br />
        </div>
      </div>
    </components.Option>
  );
};

export const PhasesMultiSelect = (constants) => {
  let objCache = {};
  return {
    id: {
      type: "select",
      validationRules: "required",
      onChangeEvent: "handleSelect",
      value: "",
      md: 8,
      requestNormalizer: function (data) {
        return parseInt(data.id);
      },
      responseNormalizer: (option) => {
        let id = option;
        if (objCache[id] !== undefined) {
          return objCache[id];
        }
        return API.get("admin", `/admin/training-plans/phases/${id}`)
          .then((data) => {
            objCache[id] = data;
            return data;
          })
          .catch((error) => {
            console.error(error);
            return {};
          });
      },
      selectProps: {
        components: { Option },
        getOptionLabel: (option) => {
          return `[${option.id}] ${option.name}`;
        },
        getOptionValue: (option) => {
          return option.id;
        },
        pageSize: 10,
        minMenuHeight: 300,
        maxMenuHeight: 1000,
        loadOptions: debounce(loadPhases, 2000),
      },
    },
  };
};

export const TrainingPlanModel = (
  constants,
  get_const,
  fitness_components,
  body_recompositions
) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    service_name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    description: {
      type: "input",
      validationRules: ["required", { max: "600" }],
      value: "",
      selectProps: {
        rows: 7,
        componentClass: "textarea",
        as: "textarea",
      },
    },
    fitness_components: {
      type: "fixed_table_collection",
      value: fitness_components,
      prototype: {
        id: {
          value: null,
          hidden: true,
        },
        name: {
          type: "input",
          validationRules: "required",
          value: "",
          md: 3,
          selectProps: {
            disabled: true,
          },
        },
        value: {
          type: "number",
          validationRules: "required|min:0,num|max:5,num|integer",
          value: "",
          md: 3,
        },
      },
      onChangeEvent: "handleCollection",
      validationRules: "",
      md: 12,
      requestNormalizer: function (data) {
        return data.map((x) => ({ id: x.id, value: parseInt(x.value) }));
      },
      responseNormalizer: function (data) {
        return data;
      },
    },
    body_recompositions: {
      type: "fixed_table_collection",
      value: body_recompositions,
      prototype: {
        id: {
          value: null,
          hidden: true,
        },
        name: {
          type: "input",
          validationRules: "required",
          value: "",
          md: 3,
          selectProps: {
            disabled: true,
          },
        },
        value: {
          type: "number",
          validationRules: "required|min:0,num|max:5,num|integer",
          value: "",
          md: 3,
        },
      },
      onChangeEvent: "handleCollection",
      validationRules: "",
      md: 12,
      requestNormalizer: function (data) {
        return data.map((x) => ({ id: x.id, value: parseInt(x.value) }));
      },
      responseNormalizer: function (data) {
        return data;
      },
    },
    translations: {
      type: "table_collection",
      value: [],
      prototype: TranslationMultiSelect(constants, {
        name: {
          type: "input",
          validationRules: "required",
          value: "",
          md: 3,
        },
        description: {
          type: "input",
          validationRules: ["required", { max: "600" }],
          value: "",
          selectProps: {
            rows: 7,
            componentClass: "textarea",
            as: "textarea",
          },
          md: 6,
        },
      }),
      onChangeEvent: "handleCollection",
      validationRules: "",
      md: 12,
      requestNormalizer: function (data) {
        return data;
      },
    },
    cover: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    activity_type: {
      ...buildSelector(
        constToSelectOptions(constants, "training_plan_activity_type"),
        "select"
      ),
      altname: "Plan Activity Type",
    },
    fitness_level: buildSelector(
      constToSelectOptions(constants, "fitness_level"),
      "multiSelect"
    ),
    gender: buildSelector(
      constToSelectOptions(constants, "gender"),
      "multiSelect"
    ),
    bmi: buildSelector(constToSelectOptions(constants, "bmi"), "multiSelect"),
    age_group: buildSelector(
      constToSelectOptions(constants, "age_group"),
      "multiSelect"
    ),
    can_be_done_every_day: yesNoSelector(),
    status: buildSelector(
      constToSelectOptions(constants, "training_plan_status"),
      "select"
    ),
    default_training_days: {
      ...buildSelector(
        constToSelectOptions(constants, "weekday"),
        "multiSelect"
      ),
      responseNormalizer: (data) => {
        return Object.entries(data)
          .filter(([key, value], _) => {
            return value === true;
          })
          .map(([key, _]) => {
            return { value: key, label: get_const("weekday", key) };
          });
      },
      requestNormalizer: (data) => {
        return Object.fromEntries(data.map((x) => [x.value, true]));
      },
    },
    training_days_min: {
      inputType: "number",
      validationRules: "required|min:1,num|max:5,num|integer",
      value: "",
      requestNormalizer: function (data) {
        return parseInt(data);
      },
    },
    training_days_max: {
      inputType: "number",
      validationRules: "required|min:3,num|max:7,num|integer",
      value: "",
      requestNormalizer: function (data) {
        return parseInt(data);
      },
    },
    phases: {
      type: "table_collection",
      value: [],
      prototype: PhasesMultiSelect(constants),
      onChangeEvent: "handleCollection",
      validationRules: "required",
      md: 12,
      requestNormalizer: function (data) {
        return data.map((x) => x.id);
      },
    },
  };
};

export const TrainingPlanFilter = (constants) => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    search: {
      type: "input",
      value: "",
    },
    status: buildSelector(
      constToSelectOptions(constants, "training_plan_status"),
      "multiSelect",
      (data) => {
        return JSON.stringify(data.map((a) => a.value));
      }
    ),
  };
};
