import React, { Component } from "react";

class Loader extends Component {
  render() {
    if (this.props.isLoading) {
      return <h1 className="text-center">loading...</h1>;
    } else {
      return this.props.children;
    }
  }
}

export default Loader;
