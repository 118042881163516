import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import { API } from "aws-amplify";
import { ContentFeedModel } from "../../models/ContentFeed";

export default class ContentFeedAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.state = {
      articles: {},
    };
    this.name = "Content Feed";
    this.url = "/admin/content/feeds";
  }

  componentDidMount() {
    API.get("admin", "/admin/content", {
      queryStringParameters: {
        per_page: "1000",
      },
    })
      .then((data) => {
        let articles = data.items.reduce(function (obj, item) {
          obj[item.id] = `[${item.external_id}] ${item.title}`;
          return obj;
        }, {});
        this.setState({
          articles: articles,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEntity = () => {
    return ContentFeedModel(this.state.articles);
  };
}
