import React, { Component } from "react";
import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { BlockFilter } from "../../models/Block";
import API from "@aws-amplify/api";
import { formatDuration } from "../../utils/Utils";

export default class Block extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/blocks/add";
    this.editPath = "/admin/blocks/edit";

    this.state = {
      tags: [],
    };
  }

  componentDidMount() {
    API.get("admin", "/admin/blocks/tags")
      .then((data) => {
        let tags = data.items.reduce(function (obj, item) {
          obj[item.id] = `[${item.id}] ${item.name}`;
          return obj;
        }, {});
        this.setState({
          tags: tags,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    let constants = this.context.constants;

    return (
      <List
        name="Block"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/blocks"
        listApiUrl="/admin/blocks"
        model={[
          { col: "Id", row: "id" },
          { col: "Name", row: "name" },
          {
            col: "Total time",
            row: "total_time",
            normalizer: (data) => {
              return formatDuration(data.total_time);
            },
          },
          {
            col: "Exercises",
            row: "exercises",
            normalizer: (data) => {
              return data.exercises.map((a, i) => (
                <div key={i}>{`${i + 1}. ${a.name}`}</div>
              ));
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={BlockFilter(constants, this.state.tags)}
        userHasPermission={this.props.userHasPermission}
      />
    );
  }
}
