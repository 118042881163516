import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import { BodyPartTranslationFilter } from "../../../models/BodyPartTranslation";

export default class BodyPartTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Body Part Translations";
    this.addPath = "/admin/translations/body-parts/add";
    this.editPath = "/admin/translations/body-parts/edit";
    this.removeUrl = "/admin/translations/body-parts";
    this.listApiUrl = "/admin/translations/body-parts";
    this.constantKey = "body_part";
  }

  generateFilter = () => {
    return BodyPartTranslationFilter();
  };
}
