import React, { Component } from "react";
import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { GuideFilter } from "../../models/Guide";

export default class Guide extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/guides/add";
    this.editPath = "/admin/guides/edit";
  }

  render() {
    let get_const = this.context.get_const;
    return (
      <List
        name="Guide"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/guides"
        listApiUrl="/admin/guides"
        model={[
          { col: "Id", row: "id" },
          {
            col: "Cover",
            row: "cover",
            normalizer: (data) => {
              return (
                <a href={data.cover} target={"blank"}>
                  <img alt={data.name} src={data.cover} width="150" />
                </a>
              );
            },
          },
          { col: "Name", row: "name" },
          { col: "Steps", row: "steps_num" },
          {
            col: "Status",
            row: "status",
            normalizer: (data) => {
              let colors = {
                published: "green",
                not_published: "orange",
                soft_deleted: "red",
              };
              return (
                <p style={{ color: colors[data.status] || "black" }}>
                  {get_const("guide_status", data.status)}
                </p>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={GuideFilter(this.context.constants)}
        userHasPermission={this.props.userHasPermission}
      />
    );
  }
}
