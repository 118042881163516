import React, { Component } from "react";
import List from "../../components/List/List";
import Button from "../../components/CustomButton/CustomButton";
import API from "@aws-amplify/api";
import FormControl from "react-bootstrap/lib/FormControl";

class Contentful extends Component {
  constructor(props) {
    super(props);
    this.syncUrl = "/admin/contentful/sync";
    this.state = { time: Date.now(), syncDisabled: false };
  }

  syncContent = (event) => {
    let button = event.target;
    button.disabled = true;

    API.post("admin", this.syncUrl, { body: {} })
      .then((data) => {
        this.props.handleClick("Synchronization initiated", "success", "tr");
        this.setState({ time: Date.now(), syncDisabled: true });
      })
      .catch((error) => {
        this.props.handleClick(
          "Failed to sync: " + JSON.stringify(error.response.data),
          "error",
          "tr"
        );
      })
      .finally(() => {
        button.disabled = false;
      });
  };

  render() {
    return (
      <List
        key={this.state.time}
        disableActions
        headerButtons={[
          () => {
            return (
              <Button
                fill
                variant="primary"
                bsStyle="success"
                disabled={this.state.syncDisabled}
                onClick={(e) => this.syncContent(e)}
              >
                Synchronize contentful
              </Button>
            );
          },
          () => {
            return (
              <Button
                fill
                variant="primary"
                bsStyle="primary"
                type="submit"
                onClick={(e) => this.setState({ time: Date.now() })}
              >
                ↻ Refresh
              </Button>
            );
          },
        ]}
        name="Contentful sync log"
        listApiUrl="/admin/contentful/sync"
        model={[
          { col: "Id", row: "id" },
          { col: "Created at", row: "created_at" },
          {
            col: "Status",
            row: "status",
            normalizer: (data) => {
              let colors = {
                finished: "green",
                in_progress: "orange",
                failed: "red",
              };
              return (
                <p style={{ color: colors[data.status] || "black" }}>
                  {data.status}
                </p>
              );
            },
          },
          {
            col: "Log",
            row: "log",
            normalizer: (data) => {
              return (
                <FormControl
                  rows={10}
                  readOnly
                  componentClass="textarea"
                  as="textarea"
                  value={data.log || ""}
                />
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
      ></List>
    );
  }
}

export default Contentful;
