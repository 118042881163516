import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import { BlockTypeTranslationFilter } from "../../../models/BlockTypeTranslation";

export default class BlockTypeTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Block Type Translations";
    this.addPath = "/admin/translations/block-types/add";
    this.editPath = "/admin/translations/block-types/edit";
    this.removeUrl = "/admin/translations/block-types";
    this.listApiUrl = "/admin/translations/block-types";
    this.constantKey = "block_type";
  }

  generateFilter = () => {
    return BlockTypeTranslationFilter();
  };
}
