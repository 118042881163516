import React, { Component } from "react";
import { ApiConstantsContext } from "../../../variables/ApiConstantsContext";
import defaults from "../../../defaults";
import { API } from "aws-amplify";
import { Col, Grid, Row, Table } from "react-bootstrap";
import Card from "../../../components/Card/Card";
import Loader from "../../../components/Loader/Loader";
import { toTitleCase } from "../../../utils/Utils";
import FormControl from "react-bootstrap/lib/FormControl";
import Button from "react-bootstrap/lib/Button";

let g = defaults.userGroups;

export default class AudioTrackTranslation extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  sortedLanguageKeys() {
    let constants = this.context.constants;
    let languages = Object.values(
      constants.language === undefined ? {} : constants.language.items || {}
    );
    languages.sort((a, b) => a.value - b.value);
    return languages;
  }

  handleSave(event) {
    let track_id = this.props.match.params.id;
    let body = this.sortedLanguageKeys()
      .filter((lang) => {
        return this.state.hasOwnProperty(lang.key);
      })
      .map((lang) => {
        return {
          track_id: parseInt(track_id),
          language_id: lang.value,
          text: this.state[lang.key].text || null,
          url: this.state[lang.key].url || null,
        };
      });
    API.put(
      "admin",
      `/admin/translations/audio-tracks/${this.props.match.params.id}`,
      { body: body }
    )
      .then((data) => {
        event.preventDefault();
        this.props.handleClick(
          ` Audio Track #${track_id} successfully updated`,
          "success",
          "tr"
        );
        this.props.history.goBack();
      })
      .catch((error) => {
        this.props.handleClick(
          error.response.data.error || error.response.data.message,
          "error",
          "tr"
        );
      });
  }

  updateInputValue(langKey, property, evt) {
    let translation = this.state[langKey] || {};
    translation = Object.assign(translation, {
      [property]: evt.target.value,
    });
    this.setState({
      [langKey]: translation,
    });
  }

  componentDidMount() {
    let track_id = this.props.match.params.id;

    API.get("admin", `/admin/translations/audio-tracks/${track_id}`)
      .then((data) => {
        let translations = data.reduce(function (obj, item) {
          obj[item.language] = item;
          return obj;
        }, {});
        this.setState({
          ...translations,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.error(error);
      });
  }

  render() {
    let track_id = this.props.match.params.id;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card title={`Translations for Track #${track_id}`} />
              <Card
                ctTableResponsive
                content={
                  <Loader isLoading={this.state.isLoading}>
                    <Table striped hover bordered>
                      <thead>
                        <tr>
                          <th className="col-md-1">Language</th>
                          <th>SCRIPT</th>
                          <th>URL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.sortedLanguageKeys().map((lang, index) => {
                          let translation = this.state[lang.key] || {};
                          return (
                            <tr key={index}>
                              <td>
                                <strong>{toTitleCase(lang.title || "")}</strong>
                              </td>
                              <td>
                                <FormControl
                                  rows={2}
                                  componentClass="textarea"
                                  as="textarea"
                                  value={translation.text || ""}
                                  onChange={(evt) =>
                                    this.updateInputValue(lang.key, "text", evt)
                                  }
                                />
                              </td>
                              <td>
                                <FormControl
                                  rows={2}
                                  componentClass="textarea"
                                  as="textarea"
                                  value={translation.url || ""}
                                  onChange={(evt) =>
                                    this.updateInputValue(lang.key, "url", evt)
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Button
                      allowedGroups={[g.admin, g.editor]}
                      className="btn btn-primary"
                      onClick={(e) => this.handleSave(e)}
                    >
                      Save
                    </Button>
                  </Loader>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
