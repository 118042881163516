import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import { BlockModel } from "../../models/Block";
import { API } from "aws-amplify";
import { constToSelectOptions } from "../../utils/Utils";
import debounce from "debounce-promise";

export default class BlockAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Blocks";
    this.url = "/admin/blocks";

    this.state = {
      tags: [],
    };
    this.debouncedLoadOptions = debounce(this.loadExercises, 2000);
  }

  detectSearchParameter = (input) => {
    input = input.toLowerCase();
    let enums = ["tags", "body_part", "equipment", "exercise_level"];
    if (!isNaN(input)) {
      return ["ids", JSON.stringify([parseFloat(input)])];
    }
    for (const enumKey of enums) {
      for (let [key, value] of Object.entries(
        constToSelectOptions(this.context.constants, enumKey)
      )) {
        if (value.toLowerCase().includes(input)) {
          if (enumKey === "tags") {
            key = JSON.stringify([key]);
          }
          return [enumKey, key];
        }
      }
    }
    return ["name", input];
  };

  loadExercises = (input) => {
    let queryParams = {
      per_page: "100",
    };

    if (input.length > 0) {
      let [searchParam, searchValue] = this.detectSearchParameter(input);
      queryParams[searchParam] = searchValue;
    }
    return API.get("admin", "/admin/exercises", {
      queryStringParameters: queryParams,
    })
      .then((data) => {
        let to_const = (values, key) => {
          return (values || []).map((value) =>
            this.context.get_const(key, value)
          );
        };
        let exercises = data.items.reduce((obj, item) => {
          item.body_parts = to_const(item.body_parts, "body_part");
          item.equipment = to_const(item.equipment, "equipment");
          item.tags = to_const(item.tags, "tags");
          item.exercise_level = this.context.get_const(
            "exercise_level",
            item.exercise_level
          );
          obj[item.id] = item;
          return obj;
        }, {});
        return Object.values(exercises);
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  componentDidMount() {
    API.get("admin", "/admin/blocks/tags")
      .then((data) => {
        let tags = data.items.reduce(function (obj, item) {
          obj[item.id] = `[${item.id}] ${item.name}`;
          return obj;
        }, {});
        this.setState({
          tags: tags,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEntity = () => {
    return BlockModel(this.context.constants, this.state.tags, (inputValue) =>
      this.debouncedLoadOptions(inputValue)
    );
  };

  render() {
    return React.cloneElement(super.render(), { md: 12 });
  }
}
