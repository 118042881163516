import React, { Component } from "react";
import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import defaults from "../../defaults";

let g = defaults.userGroups;

export default class Tag extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/blocks/tags/add";
    this.editPath = "/admin/blocks/tags/edit";
  }

  render() {
    return (
      <List
        name="Tag"
        addPath={this.addPath}
        editPath={this.editPath}
        actions={[
          (list, data) => {
            return (
              <NavLink
                to={{
                  pathname: list.props.editPath + "/" + data.id,
                  state: {
                    tag: data,
                  },
                }}
                className="btn btn-primary"
              >
                Edit
              </NavLink>
            );
          },
          (list, data) => {
            return (
              <Button
                allowedGroups={[g.admin, g.editor]}
                className="btn btn-danger"
                onClick={(e) => list.handleRemove(data.id, e)}
              >
                Delete
              </Button>
            );
          },
        ]}
        removeUrl="/admin/blocks/tags"
        listApiUrl="/admin/blocks/tags"
        model={[
          { col: "Id", row: "id" },
          { col: "Name", row: "name" },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
      ></List>
    );
  }
}
