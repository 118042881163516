import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import React from "react";
import { FitnessComponentModel } from "../../../models/FitnessComponent";

export default class TrainingPlanFitnessComponentAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Training Plan Fitness Component";
    this.url = "/admin/training-plans/fitness-components";
  }

  getEntity = () => {
    return FitnessComponentModel(this.context.constants);
  };

  render() {
    return React.cloneElement(super.render(), { md: 12 });
  }
}
