import { buildSelector, constToSelectOptions } from "../utils/Utils";
import { components } from "react-select";
import React from "react";
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";

const Option = ({ children, ...props }) => {
  let option = props.data;

  return (
    <components.Option {...props}>
      <div className="row clearfix" style={{ display: "flex" }}>
        <div className="col-auto">
          <img
            alt={"Preview"}
            className="pull-left mr-2 img-rounded"
            width="128"
            height="128"
            src={option.featured_image_url}
          />
        </div>
        <div className="col-auto">
          <p>
            <NavLink
              to={{
                pathname: option.url,
              }}
              target="_blank"
              style={{ color: "black" }}
            >
              <strong>{`[${option.id}] ${option.title}`}</strong>{" "}
              <span className="glyphicon glyphicon-new-window" />
            </NavLink>
          </p>
          <div style={{ flexDirection: "column", display: "flex" }}>
            <span>
              <strong>Avalon Id:</strong> {option.external_id}
            </span>
            <span>
              <strong>Category name: </strong>
              {option.category_name}
            </span>
            <span>
              <strong>Category code: </strong>
              {option.category_code}
            </span>
          </div>
        </div>
      </div>
    </components.Option>
  );
};

export const GuideExerciseModel = (loadOptions) => {
  let objCache = {};
  return {
    article_id: {
      type: "select",
      validationRules: "required",
      onChangeEvent: "handleSelect",
      value: "",
      requestNormalizer: (option) => {
        return option.id;
      },
      responseNormalizer: (option) => {
        let option_id = option;
        if (objCache[option_id] !== undefined) {
          return objCache[option_id];
        }
        return API.get("admin", `/admin/content`, {
          queryStringParameters: { ids: JSON.stringify([option_id]) },
        })
          .then((data) => {
            let option = data.items[0];
            objCache[option_id] = option;
            return option;
          })
          .catch((error) => {
            console.error(error);
            return {};
          });
      },
      selectProps: {
        getOptionLabel: (option) => {
          return `[${option.id}] ${option.title}`;
        },
        getOptionValue: (option) => {
          return option.id;
        },
        pageSize: 10,
        components: { Option },
        minMenuHeight: 300,
        maxMenuHeight: 1000,
        loadOptions: loadOptions,
        // defaultOptions: true,
      },
      md: 10,
    },
    time_to_read: {
      inputType: "number",
      validationRules: "required",
      value: 0,
      requestNormalizer: (data) => {
        return parseInt(data);
      },
      md: 2,
    },
  };
};

export const GuideFilter = (constants) => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    name: {
      type: "input",
      value: "",
    },
    status: buildSelector(
      constToSelectOptions(constants, "guide_status"),
      "multiSelect",
      (data) => {
        return JSON.stringify(data.map((a) => a.value));
      }
    ),
  };
};

export const GuideModel = (constants, loadExercises) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    cover: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    color: {
      type: "input",
      value: "7BB7AF",
      validationRules: ["required", { regex: "^[0-9A-F]{6}$" }],
    },
    status: buildSelector(constToSelectOptions(constants, "guide_status")),
    steps: {
      type: "table_collection",
      value: [],
      prototype: GuideExerciseModel(loadExercises),
      onChangeEvent: "handleCollection",
      validationRules: "required",
    },
  };
};
