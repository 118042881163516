import React from "react";
import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import { AudioTrackModel } from "../../../models/AudioTrack";

export default class AudioTrackAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Audio Track";
    this.url = "/admin/audio-tracks";
  }

  getEntity = () => {
    return AudioTrackModel(this.locationState().category_id);
  };

  render() {
    return React.cloneElement(super.render(), {
      md: 12,
    });
  }
}
