import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {UserEditModel} from "../../models/User";

export default class UserEdit extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.state = {
      workouts: {},
    };
    this.name = "User";
    this.url = "/admin/client-users";
    this.redirectUrl = "/admin/users";
  }

  getEntity = () => {
    return UserEditModel(this.context.constants);
  };
}
