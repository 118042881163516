import {
  buildSelector,
  constToSelectOptions,
  formatDuration,
  generateBadgeRow,
  toTitleCase,
} from "../utils/Utils";
import { components } from "react-select";
import { API } from "aws-amplify";
import React from "react";
import debounce from "debounce-promise";

const loadWorkouts = (input) => {
  let queryParams = {
    per_page: "25",
  };

  if (input.length > 0) {
    let [searchParam, searchValue] = detectSearchParameter(input);
    queryParams[searchParam] = searchValue;
  }

  return API.get("admin", "/admin/workouts", {
    queryStringParameters: queryParams,
  })
    .then((data) => {
      let items = data.items.reduce(function (obj, item) {
        obj[item.id] = item;
        return obj;
      }, {});
      return Object.values(items);
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

const detectSearchParameter = (input) => {
  input = input.toLowerCase();
  if (!isNaN(input)) {
    return ["ids", JSON.stringify([parseFloat(input)])];
  }
  return ["workout_name", input];
};

const Option = ({ children, ...props }) => {
  let option = props.data;
  let workoutStatusColors = {
    published: "success",
    not_published: "warning",
    soft_deleted: "danger",
  };
  return (
    <components.Option {...props}>
      <div className="clearfix">
        <div className="mb-3 pull-left">
          <img
            alt={"Preview"}
            className="pull-left mr-2 img-rounded"
            width="128"
            height="128"
            src={option.preview}
          />
        </div>
        <div className="mb-9 pull-left" style={{ marginLeft: "10px" }}>
          <p style={{ margin: "0" }}>
            <strong>{`[${option.id}] ${option.name}`}</strong>
          </p>
          {generateBadgeRow("Workout Type", [toTitleCase(option.workout_type)])}
          <br />
          {generateBadgeRow(
            "Status",
            [toTitleCase(option.status)],
            workoutStatusColors[option.status] || "danger"
          )}
          <br />
          <span style={{ margin: "0" }}>
            Service name: <strong>{option.service_name || "-"}</strong>
          </span>
          <br />
          <span style={{ margin: "0" }}>
            Total Time: <strong>{formatDuration(option.total_time)}</strong>
          </span>
          <br />
        </div>
      </div>
    </components.Option>
  );
};

export const WorkoutMultiSelect = (constants) => {
  let objCache = {};
  return {
    id: {
      type: "select",
      validationRules: "required",
      onChangeEvent: "handleSelect",
      value: "",
      md: 8,
      requestNormalizer: function (data) {
        return parseInt(data.id);
      },
      responseNormalizer: (option) => {
        let id = option;
        if (objCache[id] !== undefined) {
          return objCache[id];
        }
        return API.get("admin", `/admin/workouts/${id}`)
          .then((data) => {
            objCache[id] = data;
            return data;
          })
          .catch((error) => {
            console.error(error);
            return {};
          });
      },
      selectProps: {
        components: { Option },
        getOptionLabel: (option) => {
          return `[${option.id}] ${option.name}`;
        },
        getOptionValue: (option) => {
          return option.id;
        },
        pageSize: 10,
        minMenuHeight: 300,
        maxMenuHeight: 1000,
        loadOptions: debounce(loadWorkouts, 2000),
        // defaultOptions: true,
      },
    },
  };
};

export const WorkoutModel = (constants) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    service_name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    preview: {
      type: "input",
      validationRules: ["required", { regex: "^https://mobile.appscdn.io/.*" }],
      value: "",
    },
    workout_type: buildSelector(
      constToSelectOptions(constants, "workout_type")
    ),
  };
};

export const WorkoutFilter = (constants) => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    workout_name: {
      type: "input",
      value: "",
    },
    workout_type: buildSelector(
      constToSelectOptions(constants, "workout_type"),
      "multiSelect",
      (data) => {
        return JSON.stringify(data.map((a) => a.value));
      },
      undefined,
      4
    ),
    status: buildSelector(
      constToSelectOptions(constants, "workout_status"),
      "multiSelect",
      (data) => {
        return JSON.stringify(data.map((a) => a.value));
      }
    ),
  };
};
