import { buildSelector, gendersSelectorWithoutOther } from "../utils/Utils";

export const AudioTrackCategoryModel = (constants) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    gender: buildSelector(gendersSelectorWithoutOther(constants), "select"),
  };
};

export const AudioTrackCategoryFilter = (constants) => {
  return {
    gender: buildSelector(
      gendersSelectorWithoutOther(constants),
      "multiSelect",
      (data) => {
        return JSON.stringify(data.map((a) => a.value));
      }
    ),
  };
};
