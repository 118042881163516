import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import { BlockTypeTranslationModel } from "../../../models/BlockTypeTranslation";

export default class BlockTypeTranslationAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Block Type Translation";
    this.url = "/admin/translations/block-types";
    this.skipPrepopulation = true;
  }

  locationState = () => {
    return this.props.location.state || { language: {}, translation: {} };
  };

  getTitle = () => {
    return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${
      this.locationState().language.title
    }`;
  };

  getEntity = () => {
    let state = this.locationState();
    let translation = state.translation || {};
    return BlockTypeTranslationModel(
      state.resource || null,
      state.language.key || null,
      translation.name || null
    );
  };
}
