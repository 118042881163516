import React from "react";
import { Label } from "react-bootstrap";

export const mapSelect = (dict, key) => {
  if (key === null) {
    return null;
  }
  return { value: key, label: dict[key.toString()] };
};

export const buildSelector = (
  options,
  type = "select",
  requestNormalizer,
  responseNormalizer,
  md
) => {
  return {
    type: type,
    validationRules: "required",
    onChangeEvent: "handleSelect",
    selectOptions: Object.keys(options).map(function (key) {
      return mapSelect(options, key);
    }),
    value: "",
    requestNormalizer:
      requestNormalizer ||
      function (data) {
        if (data === null) {
          return null;
        }
        return type === "select"
          ? data["value"]
          : data.map((value) => {
              return value["value"];
            });
      },
    responseNormalizer:
      responseNormalizer ||
      function (data) {
        return type === "select"
          ? mapSelect(options, data)
          : data.map((value) => {
              return mapSelect(options, value);
            });
      },
    md: md || null,
  };
};

export const constToSelectOptions = (constants, key, filter) => {
  filter = filter || ((_) => true);
  let data = constants[key] === undefined ? {} : constants[key].items || {};
  return Object.fromEntries(
    Object.entries(data)
      .filter(filter)
      .map(([k, v], i) => [k, v.title])
  );
};

export const languageSelectOptions = (constants) => {
  let key = "language";
  let data = constants[key] === undefined ? {} : constants[key].items || {};
  return Object.fromEntries(
    Object.entries(data)
      .filter(([k, _]) => k !== "en")
      .map(([_, v], i) => [v.value, v.title])
  );
};

export const TranslationMultiSelect = (constants, text_obj) => {
  return {
    language_id: {
      ...buildSelector(languageSelectOptions(constants)),
      md: 2,
      requestNormalizer: function (data) {
        if (data === null) {
          return null;
        }
        return parseInt(data["value"]);
      },
    },
    ...text_obj,
  };
};

export const toTitleCase = (str) => {
  return str.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getConstantTitle = (constants, group, item, default_value) => {
  let data = constants[group] === undefined ? {} : constants[group].items || {};
  if (item !== undefined) {
    data = data[item];
  }
  return (data || {})["title"] || default_value;
};

export const constList = (c, data, dataKey, constKey) => {
  return (
    <ul>
      {data[dataKey].map((k, index) => {
        return <li key={index}>{c(constKey || dataKey, k)}</li>;
      })}
    </ul>
  );
};

export const generateBadgeRow = (title, options, style = "warning") => {
  return (
    <span style={{ margin: "0" }}>
      {title}:
      {(options || []).map((value, index) => {
        return (
          <strong key={index}>
            <Label
              style={{ marginLeft: "4px" }}
              // Use "badge alert-success" or "badge progress-bar-success"
              // For alternative badges
              // info, success, danger, warning, primary
              className={`alert-${style}`}
            >
              {value}
            </Label>
          </strong>
        );
      })}
    </span>
  );
};

const f = (a, b) => [].concat(...a.map((d) => b.map((e) => [].concat(d, e))));
export const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);

export function yesNoSelector() {
  let options = { yes: "Yes", no: "No" };
  return {
    ...buildSelector(options, "select"),
    responseNormalizer: (data) => {
      let key = "no";
      if (data === true) {
        key = "yes";
      }
      return { value: key, label: options[key] };
    },
    requestNormalizer: (data) => {
      return data.value === "yes";
    },
  };
}

export const gendersSelectorWithoutOther = (constants) =>
  constToSelectOptions(constants, "gender", ([k, _]) => k !== "other");

export const formatDuration = (seconds) => {
  let date = new Date(0);
  date.setSeconds(seconds); // specify value for SECONDS here
  return date.toISOString().substr(11, 8);
};
