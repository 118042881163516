import { buildSelector } from "../utils/Utils";

export const ArticlesModel = (articles) => {
  return {
    id: buildSelector(articles, "select", undefined, (data) => {
      return { value: data.toString(), label: data.toString() };
    }),
  };
};

export const ContentFeedModel = (articles) => {
  return {
    day: {
      inputType: "number",
      validationRules: "min:0,num",
      value: "",
      requestNormalizer: function (data) {
        return parseInt(data);
      },
    },
    articles: {
      type: "collection",
      value: [],
      prototype: ArticlesModel(articles),
      onChangeEvent: "handleCollection",
      validationRules: "required",
      requestNormalizer: (data) => {
        return data.map((a) => parseInt(a.id));
      },
    },
  };
};
