import React from "react";
import { WorkoutBlockListModel } from "../../../models/WorkoutBlock";
import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import { API } from "aws-amplify";
import debounce from "debounce-promise";

export default class WorkoutBlockListEdit extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Workout Block List";
    this.url = "/admin/workouts";

    this.debouncedLoadOptions = debounce(this.loadBlocks, 2000);
  }

  loadBlocks = (input) => {
    let queryParams = {
      per_page: "100",
    };

    if (input.length > 0) {
      if (isNaN(input)) {
        queryParams["search"] = input;
      } else {
        queryParams["ids"] = JSON.stringify([parseInt(input)]);
      }
    }

    return API.get("admin", "/admin/blocks", {
      queryStringParameters: queryParams,
    })
      .then((data) => {
        let to_const = (values, key) => {
          return (values || []).map((value) =>
            this.context.get_const(key, value)
          );
        };

        let blocks = data.items.reduce(function (obj, item) {
          item.body_parts = to_const(item.body_parts, "body_part");
          obj[item.id] = item;
          return obj;
        }, {});
        return Object.values(blocks);
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  getEntity = () => {
    return WorkoutBlockListModel(
      this.context.constants,
      this.debouncedLoadOptions
    );
  };

  render() {
    return React.cloneElement(super.render(), {
      md: 12,
      id: `${this.props.match.params.id}/blocks`,
    });
  }
}
