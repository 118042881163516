import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import { TrainingPlanModel } from "../../models/TrainingPlan";
import React from "react";
import { API } from "aws-amplify";

export default class TrainingPlanAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Training Plan";
    this.url = "/admin/training-plans";
    this.myRef = React.createRef();
    this.state = {
      fitness_components: [],
      body_recompositions: [],
    };
  }

  componentDidMount() {
    if (this.isEdit()) {
      return;
    }
    API.get("admin", "/admin/training-plans/body-recompositions", {
      queryStringParameters: {
        per_page: "1000",
      },
    })
      .then((data) => {
        let body_recompositions = data.items.map((x) => ({
          name: x.name,
          id: x.id,
          value: 3,
        }));
        this.setState(
          {
            body_recompositions: body_recompositions,
          },
          () => {
            this.myRef.current.getDefaultValues().then((value) => {
              this.myRef.current.setState({
                defaults: {
                  ...value,
                  body_recompositions: body_recompositions,
                },
              });
            });
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });

    API.get("admin", "/admin/training-plans/fitness-components", {
      queryStringParameters: {
        per_page: "1000",
      },
    })
      .then((data) => {
        let fitness_components = data.items.map((x) => ({
          name: x.name,
          id: x.id,
          value: 3,
        }));
        this.setState(
          {
            fitness_components: fitness_components,
          },
          () =>
            this.myRef.current.getDefaultValues().then((value) => {
              this.myRef.current.setState({
                defaults: {
                  ...value,
                  fitness_components: fitness_components,
                },
              });
            })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEntity = () => {
    return TrainingPlanModel(
      this.context.constants,
      this.context.get_const,
      this.state.fitness_components,
      this.state.body_recompositions
    );
  };

  render() {
    return React.cloneElement(super.render(), { md: 12, ref: this.myRef });
  }
}
