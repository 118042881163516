import { buildSelector, constToSelectOptions } from "../utils/Utils";

export const UserFilter = {
  ids: {
    type: "input",
    value: "",
    placeholder: "Ids separated by comma",
    requestNormalizer: (data) => {
      return JSON.stringify(
        Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0])),
      );
    },
  },
  email: {
    type: "input",
    value: "",
  },
};

export const UserEditModel = (constants) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    gifted_product_list: {
      ...buildSelector(
        constToSelectOptions(constants, "product_code"),
        "multiSelect",
      ),
      validationRules: "",
    },
  };
};
