import { WorkoutMultiSelect } from "./Workout";
import { TranslationMultiSelect } from "../utils/Utils";

export const TrainingPlanPhaseModel = (constants) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    service_name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    description: {
      type: "input",
      validationRules: ["required", { max: "600" }],
      value: "",
      selectProps: {
        rows: 7,
        componentClass: "textarea",
        as: "textarea",
      },
    },
    translations: {
      type: "table_collection",
      value: [],
      prototype: TranslationMultiSelect(constants, {
        name: {
          type: "input",
          validationRules: "required",
          value: "",
          md: 3,
        },
        description: {
          type: "input",
          validationRules: ["required", { max: "600" }],
          value: "",
          selectProps: {
            rows: 7,
            componentClass: "textarea",
            as: "textarea",
          },
          md: 6,
        },
      }),
      onChangeEvent: "handleCollection",
      validationRules: "",
      md: 12,
      requestNormalizer: function (data) {
        return data;
      },
    },
    required_completes_count: {
      inputType: "number",
      validationRules: "required|min:1,num",
      value: "",
      requestNormalizer: (data) => {
        return parseInt(data);
      },
    },
    workouts: {
      type: "table_collection",
      value: [],
      prototype: WorkoutMultiSelect(constants),
      onChangeEvent: "handleCollection",
      validationRules: "required",
      md: 12,
      requestNormalizer: function (data) {
        return data.map((x) => x.id);
      },
    },
  };
};

export const TrainingPlanPhaseFilter = (constants) => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    search: {
      type: "input",
      value: "",
    },
  };
};
