import React, { Component } from "react";
import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { constToSelectOptions, formatDuration } from "../../utils/Utils";
import { WorkoutFilter } from "../../models/Workout";
import Button from "react-bootstrap/lib/Button";
import { NavLink } from "react-router-dom";
import defaults from "../../defaults";
import API from "@aws-amplify/api";

let g = defaults.userGroups;

export default class Workout extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/workouts/add";
    this.editPath = "/admin/workouts/edit";
    this.state = {
      key: 0,
    };
  }

  setWorkoutStatus = (event, workout_id, status) => {
    let button = event.target;
    button.disabled = true;

    let url = `/admin/workouts/${workout_id}/status`;

    API.put("admin", url, { body: { status: status } })
      .then((data) => {
        this.props.handleClick(
          `Successfully set status to ${status}`,
          "success",
          "tr"
        );
        this.setState({ key: Math.random() });
      })
      .catch((error) => {
        this.props.handleClick(
          "Failed to set status: " + JSON.stringify(error.response.data),
          "error",
          "tr"
        );
      })
      .finally(() => {
        button.disabled = false;
      });
  };

  render() {
    let constants = this.context.constants;
    let get_const = this.context.get_const;
    let workoutTypes = constToSelectOptions(constants, "workout_type");

    return (
      <List
        key={this.state.key}
        name="Workout"
        actions={[
          (list, data) => {
            return (
              <NavLink
                to={{
                  pathname: list.props.editPath + "/" + data.id,
                  state: {
                    workout: data,
                  },
                }}
                className="btn btn-primary btn-fill"
              >
                Edit
              </NavLink>
            );
          },
          (list, data) => {
            return (
              <NavLink
                to={{
                  pathname: `workouts/${data.id}/blocks`,
                  state: { user_id: data.id },
                }}
                className="btn btn-success btn-fill"
              >
                Blocks
              </NavLink>
            );
          },
          (list, data) => {
            if (data.status === "soft_deleted") {
              return undefined;
            }
            return <hr className="separator" />;
          },
          (list, data) => {
            if (data.status === "soft_deleted") {
              return undefined;
            }
            return (
              <Button
                allowedGroups={[g.admin, g.editor]}
                className="btn btn-danger btn-fill"
                onClick={(e) => list.handleRemove(data.id, e)}
              >
                Delete
              </Button>
            );
          },
          (list, data) => {
            if (data.status === "soft_deleted") {
              return undefined;
            } else if (data.status === "published") {
              return (
                <Button
                  className="btn btn-warning btn-fill"
                  onClick={(e) =>
                    this.setWorkoutStatus(e, data.id, "not_published")
                  }
                >
                  Unpublish
                </Button>
              );
            } else {
              return (
                <Button
                  className="btn btn-info btn-fill"
                  onClick={(e) =>
                    this.setWorkoutStatus(e, data.id, "published")
                  }
                >
                  Publish
                </Button>
              );
            }
          },
        ]}
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/workouts"
        listApiUrl="/admin/workouts"
        model={[
          { col: "Id", row: "id" },
          { col: "Name", row: "name" },
          {
            col: "Preview",
            row: "preview",
            normalizer: (data) => {
              return (
                <a href={data.preview} target={"blank"}>
                  <img alt={data.name} src={data.preview} width="150" />
                </a>
              );
            },
          },
          {
            col: "Workout Type",
            row: "workout_type",
            normalizer: (data) => {
              return workoutTypes[data.workout_type];
            },
          },
          {
            col: "Total time",
            row: "total_time",
            normalizer: (data) => {
              return formatDuration(data.total_time);
            },
          },
          { col: "AVG mets", row: "total_mets" },
          { col: "Exercises count", row: "total_exercise_count" },
          {
            col: "Status",
            row: "status",
            normalizer: (data) => {
              let colors = {
                published: "green",
                not_published: "orange",
                soft_deleted: "red",
              };
              return (
                <p style={{ color: colors[data.status] || "black" }}>
                  {get_const("workout_status", data.status)}
                </p>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={WorkoutFilter(constants)}
        userHasPermission={this.props.userHasPermission}
      />
    );
  }
}
