import React, { Component } from "react";
import List from "../../components/List/List";
import defaults from "../../defaults";
import { ReviewerAccountFilter } from "../../models/ReviewerAccount";
import EditButton from "../../components/List/EditButton";
import DeleteButton from "../../components/List/DeleteButton";
import API from "@aws-amplify/api";
import Button from "../../components/CustomButton/CustomButton";

let g = defaults.userGroups;

class ReviewerAccount extends Component {
  constructor(props) {
    super(props);
    this.addPath = "/admin/reviewer-accounts/add";
    this.editPath = "/admin/reviewer-accounts/edit";
    this.state = { time: Date.now() };
  }

  resetAccount = (event, user_id) => {
    let button = event.target;
    button.disabled = true;

    API.put("admin", `/admin/reviewer-accounts/${user_id}/reset`, { body: {} })
      .catch((error) => {
        this.props.handleClick(
          "Failed to reset user: " + JSON.stringify(error),
          "error",
          "tr",
        );
      })
      .finally(() => {
        button.disabled = false;
        this.props.handleClick("User reset", "success", "tr");
        this.setState({ time: Date.now() });
      });
  };

  render() {
    return (
      <List
        key={this.state.time}
        name="Reviewer Accounts"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/reviewer-accounts"
        listApiUrl="/admin/reviewer-accounts"
        actions={[
          (list, data_) => {
            return (
              <EditButton
                editPath={list.props.editPath}
                id={data_.id}
                allowedGroups={[g.editor, g.viewer, g.admin]}
              />
            );
          },
          (list, data_) => {
            return (
              <DeleteButton
                id={data_.id}
                handleRemove={(id, event) => {
                  if (
                    window.confirm("Are you sure you want to delete this item?")
                  ) {
                    list.handleRemove(id, event);
                  }
                }}
                allowedGroups={[g.editor, g.viewer, g.admin]}
              />
            );
          },
          (list, data) => {
            return (
              <Button
                onClick={(e) => this.resetAccount(e, data.id)}
                allowedGroups={[g.editor, g.admin]}
              >
                Reset
              </Button>
            );
          },
        ]}
        model={[
          { col: "User id", row: "id" },
          { col: "Email", row: "email" },
          { col: "Password", row: "password" },
          {
            col: "Correct password",
            row: "is_correct_password",

            normalizer: (data) => {
              let colors = {
                [true]: "green",
                [false]: "red",
              };
              return (
                <p
                  style={{ color: colors[data.is_correct_password] || "black" }}
                >
                  {JSON.stringify(data.is_correct_password)}
                </p>
              );
            },
          },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
        filterObject={ReviewerAccountFilter}
      ></List>
    );
  }
}

export default ReviewerAccount;
