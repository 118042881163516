import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import { BodyRecompositionTranslationFilter } from "../../../models/BodyRecompositionTranslation";

export default class BodyRecompositionTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Body Recomposition Translations";
    this.addPath = "/admin/translations/body-recompositions/add";
    this.editPath = "/admin/translations/body-recompositions/edit";
    this.removeUrl = "/admin/translations/body-recompositions";
    this.listApiUrl = "/admin/translations/body-recompositions";
  }

  generateFilter = () => {
    return BodyRecompositionTranslationFilter();
  };
}
