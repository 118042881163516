import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class AddButton extends Component {
  render() {
    if (this.props.addPath) {
      return (
        <NavLink to={this.props.addPath} className="btn btn-success">
          Add
        </NavLink>
      );
    }

    return "";
  }
}

export default AddButton;
