import React, { Component } from "react";
import CollectionRow from "./CollectionRow";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "../Card/Card";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { reorder } from "../../utils/Utils";

class Collection extends Component {
  constructor(props) {
    super(props);
    this.object = this.props.prototype;
    this.state = {
      items: this.props.value,
    };
  }

  componentWillReceiveProps(props) {
    // Fixme https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-controlled-component
    this.object = props.prototype;
    this.setState({ items: props.value });
    // Todo fix if in constructor labels don't get updated
    //  currently sorting/deleting doesn't work as this.items are overridden
    //  from props.value
  }

  delete = (event, index) => {
    this.setState(
      (prevState) => ({
        items: prevState.items.filter((el) => el !== prevState.items[index]),
      }),
      () => {
        this.props.onChange(this.state.items, this.props.name);
      }
    );
  };
  add = (event) => {
    const items = Object.assign([], this.state.items);
    let newItem = {};
    Object.keys(this.object).map((key) => {
      return (newItem[key] = this.object[key].value);
    });
    items.push(newItem);
    this.updateItems(items);
  };
  handleChange = (_State, index) => {
    const items = Object.assign([], this.state.items);
    items[index] = _State;
    this.updateItems(items);
  };
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.updateItems(
      reorder(this.state.items, result.source.index, result.destination.index)
    );
  };
  updateItems = (items) => {
    this.setState({ items: items }, () => {
      this.props.onChange(this.state.items, this.props.name);
    });
  };

  render() {
    return (
      <Card
        content={
          <div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <ul className="form-collection" ref={provided.innerRef}>
                    {this.state.items.map((value, index) => {
                      return (
                        <CollectionRow
                          key={index}
                          index={index}
                          object={this.object}
                          value={this.state.items[index]}
                          handleChange={this.handleChange}
                          onDelete={this.delete}
                          validator={this.props.validator}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <Button block bsStyle="success" onClick={(e) => this.add(e)}>
              Add
            </Button>
          </div>
        }
      />
    );
  }
}

export default Collection;
