import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import React from "react";
import { BodyRecompositionModel } from "../../../models/BodyRecomposition";

export default class BodyRecompositionAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Body Recomposition";
    this.url = "/admin/training-plans/body-recompositions";
  }

  getEntity = () => {
    return BodyRecompositionModel(this.context.constants);
  };

  render() {
    return React.cloneElement(super.render(), { md: 12 });
  }
}
