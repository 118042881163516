import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import { GuideModel } from "../../models/Guide";
import { API } from "aws-amplify";
import debounce from "debounce-promise";

export default class GuideAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Guides";
    this.url = "/admin/guides";

    this.debouncedLoadOptions = debounce(this.loadArticles, 2000);
  }

  detectSearchParameter = (input) => {
    if (!isNaN(input)) {
      return ["ids", JSON.stringify([parseInt(input)])];
    }
    return ["title", input];
  };

  loadArticles = (input) => {
    let queryParams = {
      per_page: "100",
    };

    if (input.length > 0) {
      let [searchParam, searchValue] = this.detectSearchParameter(input);
      queryParams[searchParam] = searchValue;
    }
    return API.get("admin", "/admin/content", {
      queryStringParameters: queryParams,
    })
      .then((data) => {
        let exercises = data.items.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        return Object.values(exercises);
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  getEntity = () => {
    return GuideModel(this.context.constants, (inputValue) =>
      this.debouncedLoadOptions(inputValue)
    );
  };

  render() {
    return React.cloneElement(super.render(), { md: 12 });
  }
}
