import { WorkoutMultiSelect } from "./Workout";

export const AdditionalWorkoutListUpdateModel = (constants) => {
  return {
    workouts: {
      type: "table_collection",
      value: [],
      prototype: WorkoutMultiSelect(constants),
      onChangeEvent: "handleCollection",
      validationRules: "required",
      md: 12,
      requestNormalizer: function (data) {
        return data.map((x) => x.id);
      },
    },
  };
};

export const AdditionalWorkoutListAddModel = (constants, clusterParams) => {
  return {
    ...AdditionalWorkoutListUpdateModel(constants),
    ...Object.entries(clusterParams).reduce((obj, [k, v]) => {
      obj[k] = { value: v, hidden: true };
      return obj;
    }, {}),
  };
};
