import React, { Component } from "react";
import { ApiConstantsContext } from "../../../variables/ApiConstantsContext";
import List from "../../../components/List/List";
import { TrainingPlanPhaseFilter } from "../../../models/TrainingPlanPhase";

export default class TrainingPlanPhase extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/training-plans/phases/add";
    this.editPath = "/admin/training-plans/phases/edit";
  }

  render() {
    return (
      <List
        name="Training Plans Phases"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/training-plans/phases"
        listApiUrl="/admin/training-plans/phases"
        model={[
          { col: "Id", row: "id" },
          { col: "Name", row: "name" },
          { col: "Service name", row: "service_name" },
          { col: "Description", row: "description" },
          { col: "Workouts", row: "workouts_count" },
          { col: "Required Completes", row: "required_completes_count" },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
        filterObject={TrainingPlanPhaseFilter(this.context.constants)}
      />
    );
  }
}
