import React, { Component } from "react";
import { ApiConstantsContext } from "../../../variables/ApiConstantsContext";
import List from "../../../components/List/List";
import { FitnessComponentFilter } from "../../../models/FitnessComponent";

export default class TrainingPlanFitnessComponent extends Component {
  static contextType = ApiConstantsContext;

  constructor(props) {
    super(props);
    this.addPath = "/admin/training-plans/fitness-components/add";
    this.editPath = "/admin/training-plans/fitness-components/edit";
  }

  render() {
    return (
      <List
        name="Fitness Components"
        addPath={this.addPath}
        editPath={this.editPath}
        removeUrl="/admin/training-plans/fitness-components"
        listApiUrl="/admin/training-plans/fitness-components"
        model={[
          { col: "Id", row: "id" },
          { col: "Name", row: "name" },
          { col: "Description", row: "description" },
        ]}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        userHasPermission={this.props.userHasPermission}
        filterObject={FitnessComponentFilter(this.context.constants)}
      />
    );
  }
}
