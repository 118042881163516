import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import { FitnessComponentTranslationFilter } from "../../../models/FitnessComponentTranslation";

export default class FitnessComponentTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Fitness Component Translations";
    this.addPath = "/admin/translations/fitness-components/add";
    this.editPath = "/admin/translations/fitness-components/edit";
    this.removeUrl = "/admin/translations/fitness-components";
    this.listApiUrl = "/admin/translations/fitness-components";
  }

  generateFilter = () => {
    return FitnessComponentTranslationFilter();
  };
}
