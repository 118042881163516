export const FitnessComponentTranslationModel = (
  fitness_component_id,
  language_id,
  name,
  description
) => {
  return {
    fitness_component_id: {
      value: fitness_component_id,
      hidden: true,
    },
    language_id: {
      value: language_id,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: name,
    },
    description: {
      type: "input",
      validationRules: "required",
      value: description,
    },
  };
};

export const FitnessComponentTranslationFilter = () => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
  };
};
