export const AudioTrackModel = (category_id) => {
  return {
    category_id: {
      hidden: true,
      value: category_id || null,
      requestNormalizer: (data) => {
        return parseInt(data);
      },
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    time: {
      inputType: "number",
      validationRules: "min:0,num",
      value: "",
      requestNormalizer: (data) => {
        return parseInt(data);
      },
    },
    repetitions: {
      inputType: "number",
      validationRules: "min:0,num",
      value: "",
      requestNormalizer: (data) => {
        return parseInt(data);
      },
    },
  };
};
