import {
  buildSelector,
  constToSelectOptions,
  generateBadgeRow,
} from "../utils/Utils";
import { components } from "react-select";
import React from "react";
import { API } from "aws-amplify";

const Option = ({ children, ...props }) => {
  let option = props.data;

  return (
    <components.Option {...props}>
      <div className="clearfix">
        <div className="mb-3 pull-left">
          <img
            alt={"Preview"}
            className="pull-left mr-2 img-rounded"
            width="128"
            height="128"
            src={option.preview}
          />
        </div>
        <div className="mb-9 pull-left" style={{ marginLeft: "10px" }}>
          <p style={{ margin: "0" }}>
            <strong>{`[${option.id}] ${option.name}`}</strong>
          </p>
          {generateBadgeRow("Body parts", option.body_parts)}
          <br />
          {generateBadgeRow("Equipment", option.equipment, "success")}
          <br />
          {generateBadgeRow("Tags", option.tags, "danger")}
          <br />
          <span style={{ margin: "0" }}>
            Pace/Mets/One RM: <strong>{option.base_pace}</strong>/
            <strong>{option.base_mets}</strong>/<strong>{option.one_rm}</strong>
          </span>
          <br />
          <span style={{ margin: "0" }}>
            Exercise Level: <strong>{option.exercise_level}</strong>
          </span>
          <br />
        </div>
      </div>
    </components.Option>
  );
};

export const BlockExerciseModel = (constants, loadOptions) => {
  let objCache = {};
  return {
    exercise_id: {
      type: "select",
      validationRules: "required",
      onChangeEvent: "handleSelect",
      value: "",
      requestNormalizer: (option) => {
        return option.id;
      },
      responseNormalizer: (option) => {
        let exercise_id = option;
        if (objCache[exercise_id] !== undefined) {
          return objCache[exercise_id];
        }
        return API.get("admin", `/admin/exercises/${exercise_id}`)
          .then((data) => {
            objCache[exercise_id] = data;
            return data;
          })
          .catch((error) => {
            console.error(error);
            return {};
          });
      },
      selectProps: {
        getOptionLabel: (option) => {
          return `[${option["id"]}] ${option["name"]}`;
        },
        getOptionValue: (option) => {
          return option["id"];
        },
        pageSize: 10,
        components: { Option },
        minMenuHeight: 300,
        maxMenuHeight: 1000,
        loadOptions: loadOptions,
        // defaultOptions: true,
      },
      md: 5,
    },
    type: {
      ...buildSelector(
        constToSelectOptions(constants, "workout_block_exercise_type"),
        "select"
      ),
      md: 2,
    },
    time: {
      inputType: "number",
      validationRules: "required",
      value: 0,
      requestNormalizer: (data) => {
        return parseInt(data);
      },
      md: 2,
    },
    one_rm: {
      hidden: true,
      inputType: "number",
      validationRules: "required",
      value: 0,
      md: 2,
      requestNormalizer: (data) => {
        return parseInt(data);
      },
    },
    repetition: {
      inputType: "number",
      validationRules: "required",
      value: 0,
      md: 2,
      requestNormalizer: (data) => {
        return parseInt(data);
      },
    },
    reps_weight: {
      hidden: true,
      inputType: "number",
      validationRules: "required",
      value: 0,
      md: 1,
      requestNormalizer: (data) => {
        return parseInt(data);
      },
    },
  };
};

export const BlockFilter = (constants, tags) => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    search: {
      type: "input",
      value: "",
    },
    tags: buildSelector(
      tags,
      "multiSelect",
      (data) => {
        return JSON.stringify(data.map((a) => a.value));
      },
      undefined,
      4
    ),
  };
};

export const BlockModel = (constants, tags, loadExercises) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
      md: 4,
    },
    tags: buildSelector(
      tags,
      "multiSelect",
      (data) => {
        return data.map((a) => parseInt(a.value));
      },
      undefined,
      4
    ),
    exercises: {
      type: "table_collection",
      value: [],
      prototype: BlockExerciseModel(constants, loadExercises),
      onChangeEvent: "handleCollection",
      validationRules: "required",
    },
    __validator__: (component, object) => {
      for (let exercise of object.exercises) {
        if ((exercise.exercise_id.name || "").toLowerCase().includes("rest")) {
          exercise.type = { value: "rest", label: "Rest" };
        }
        if (
          (exercise.type || {}).value === "repetition" &&
          parseInt(exercise.time) === 0
        ) {
          exercise.time = (
            exercise.exercise_id.base_pace * exercise.repetition
          ).toFixed(2);
        }
      }
    },
  };
};
