import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import { AdditionalWorkoutListAddModel } from "../../../models/AdditionalWorkout";

export default class AdditionalWorkoutAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Additional Workout";
    this.url = "/admin/workouts/additional";
  }

  getEntity = () => {
    let clusterParams = this.props.location.state.clusterParams || {};
    return AdditionalWorkoutListAddModel(this.context.constants, clusterParams);
  };
}
