import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import Card from "../components/Card/Card";
import image from "assets/img/youshallnotpass.png";
import { Auth } from "aws-amplify";
import Button from "react-bootstrap/lib/Button";

export default class Unauthorized extends Component {
  render() {
    return (
      <div className="wrapper">
        <div className="content text-center">
          <Grid fluid>
            <Row md={8}>
              <Col md={8} className={"col-md-offset-2"}>
                <Card
                  title={
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      You don't have enough permissions to access this website
                    </span>
                  }
                  content={
                    <>
                      <h3 className={"title pb-1"}>
                        Please contact the sysadmins team for access:{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="mailto:sysadmins.team@wikrgroup.com"
                        >
                          sysadmins.team@wikrgroup.com
                        </a>
                      </h3>
                      <p />
                      <img
                        src={image}
                        className={"img-fluid"}
                        alt="You shall not pass"
                        style={{ maxWidth: "800px", width: "100%" }}
                      />
                      <p />
                      <div>
                        <Button onClick={() => Auth.signOut()}>
                          Login again
                        </Button>
                      </div>
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
