import AudioTrackAdd from "./AudioTrackAdd";
import React from "react";

export default class AudioTrackEdit extends AudioTrackAdd {
  constructor(props) {
    super(props);
    this.skipPrepopulation = true;
  }

  render() {
    return React.cloneElement(super.render(), {
      md: 12,
      responseData: this.locationState().data,
    });
  }
}
